<template>
  <div class="onboarding-wrapper">
    <router-view></router-view>
    <router-view name="geometricBackground"></router-view>
  </div>
</template>

<script>
import deepLinking from '@/mixins/deepLinking'

export default {
  mixins: [deepLinking]
}
</script>

<style lang="scss" scoped>
.onboarding-wrapper {
  width: 100%;
  position: relative;
  overflow-x: clip;
}
</style>
